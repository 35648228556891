<template>
  <Modal ref="modal__stat" size="sm">
    <template #title>Estadísticas</template>
    <template #content>
      <div class="modal__content">
        <div>
          <table ref="table">
            <tr>
              <th>Medida</th>
              <th>Valor</th>
            </tr>
            <tr>
              <td>Media</td>
              <td>{{ math.mean(salaries).toFixed(2) }}</td>
            </tr>
            <!-- <tr>
              <td>Error típico</td>
              <td>{{ (math.std(salaries) / math.sqrt(salaries.length)).toFixed(2) }}</td>
            </tr> -->
            <tr>
              <td>Mediana</td>
              <td>{{ math.median(salaries).toFixed(2) }}</td>
            </tr>
            <tr>
              <td>Moda</td>
              <td>{{ math.mode(salaries).map((val) => Number(val)) }}</td>
            </tr>
            <tr>
              <td>Desviación estándar</td>
              <td>{{ math.std(salaries).toFixed(2) }}</td>
            </tr>
            <!-- <tr>
              <td>Varianza</td>
              <td>{{ math.variance(salaries).toFixed(2) }}</td>
            </tr> -->
            <!-- <tr>
              <td>Curtosis</td>
              <td>{{ sampleKurtosis(salaries).toFixed(2) }}</td>
            </tr> -->
            <!-- <tr>
              <td>Coeficiente de asimetría</td>
              <td>{{ sampleSkewness(salaries).toFixed(2) }}</td>
            </tr> -->
            <!-- <tr>
              <td>Rango</td>
              <td>{{ (math.max(...salaries) - math.min(...salaries)).toFixed(2) }}</td>
            </tr> -->
            <tr>
              <td>Importe mínimo</td>
              <td>{{ math.min(salaries).toFixed(2) }}</td>
            </tr>
            <tr>
              <td>Importe máximo</td>
              <td>{{ math.max(salaries).toFixed(2) }}</td>
            </tr>
            <tr>
              <td>Suma</td>
              <td>{{ math.sum(salaries).toFixed(2) }}</td>
            </tr>
            <tr>
              <td>Cuenta</td>
              <td>{{ salaries.length.toFixed(2) }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="modal__buttons">
        <Button type="button" variant="primary" size="xsmall" @click="downloadExcel">
          <unicon
            width="16px"
            height="16px"
            name="file-download"
            fill="white"
          ></unicon>
          Descargar Excel
        </Button>
      </div>
    </template>

  </Modal>
</template>

<script>
import * as math from 'mathjs';
import { sampleKurtosis, sampleSkewness } from 'simple-statistics';
import Modal from '@/components/Modal.vue';
import Button from '@/components/buttons/Button.vue';
import * as XLSX from 'xlsx';

export default {
  components: {
    Modal,
    Button,
  },
  data() {
    return {
      isLoading: false,
      salaries: null,
      math,
      sampleKurtosis,
      sampleSkewness,
    };
  },
  methods: {
    closeModal() {
      this.$refs.modal__stat.close();
    },

    open(salaries) {
      this.salaries = salaries;
      this.$refs.modal__stat.open();
    },

    mode(arr) {
      const freq = arr.reduce((acc, val) => {
        acc[val] = (acc[val] || 0) + 1;
        return acc;
      }, {});

      const maxFreq = Math.max(...Object.values(freq));
      const modes = Object.keys(freq).filter((val) => freq[val] === maxFreq);

      return modes.length > 1 ? modes : modes[0];
    },

    downloadExcel() {
      const wb = XLSX.utils.book_new();
      const $table = this.$refs.table;
      const sheet = XLSX.utils.table_to_sheet($table);
      XLSX.utils.book_append_sheet(wb, sheet, 'estadisticas');

      XLSX.writeFile(wb, 'estadisticas.xlsx');
    },
  },

  computed: {},

  async mounted() {
    try {
      this.isLoading = true;
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.modal__content {
  padding: 1.5rem;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  height: 100%;
  & > div {
    &:nth-child(2) {
      overflow: auto;
      height: 100%;
      width: 100%;
    }
    table {
      width: 100%;
    }
  }
}
</style>
